import { render, staticRenderFns } from "./BabelfishCompass.vue?vue&type=template&id=ce754b0e&scoped=true"
import script from "./BabelfishCompass.vue?vue&type=script&lang=js"
export * from "./BabelfishCompass.vue?vue&type=script&lang=js"
import style0 from "./BabelfishCompass.vue?vue&type=style&index=0&id=ce754b0e&prod&scoped=true&lang=css"
import style1 from "./BabelfishCompass.vue?vue&type=style&index=1&id=ce754b0e&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ce754b0e",
  null
  
)

export default component.exports